$(document).ready(function(){
	
	responsive_init();
	header_replace_update();

$('body').on('click', 'a', function(e){
	if (e.ctrlKey)	/*При зажатом Ctrl открываем ссылку в новом окне*/
	{
		var win = window.open($(this).attr('href'), '_blank');
		return false;
	}
});

/* нажатие кнопки "Показать еще" */
$('#content').on('click', 'a.show-more', function(e){
	var url = $(this).attr('href');
	
	$('#content ul.pagination:last').closest('div.row').remove();
	$('#content a.show-more').remove();
	
	if (!mode || mode == 'list')
		$('<div class="loading"><div class="loader"></div></div>').insertAfter($('#content ul.list:last'));
	else
		$('<div class="loading"><div class="loader"></div></div>').insertAfter($('#content ul.plitka:last'));
	
	var params = [{ 'key': 'url', 'value': url}];
	products_request_ajax(params, undefined, undefined, undefined, false, undefined, true);
	return false;
});

/* переход по страницам */
$('#content').on('click', 'ul.pagination a', function(e){
	var url = $(this).attr('href');
	var type = $(this).data('type');
	var page = $(this).data('page');
	if (url == undefined)
		return false;
	
	if (e.ctrlKey)	/*При зажатом Ctrl открываем ссылку в новом окне*/
	{
		var win = window.open(url, '_blank');
		return false;
	}
	
	switch(type){
		case 'category':
			var params = [{ 'key': 'url', 'value': url}];
			products_request_ajax(params);
			break;
		case 'material-category':
			document.location.href = url;
			//material_request_ajax(url);
			break;
		case 'service-category':
			document.location.href = url;
			//material_request_ajax(url);
			break;
		case 'brand':
			var params = [{ 'key': 'url', 'value': url}];
			brand_request_ajax(params);
			break;
		case 'account_orders':
			return true;
		case 'account_reviews':
			return true;
	}
	return false;
});

/* сортировка */
$('#content').on('click', '#sort li a', function(){
	var url = $(this).attr('href');
	var type = $(this).attr('data-type');
	if (url == undefined)
		return false;
		
	switch(type){
		case 'category':
			var params = [{ 'key': 'url', 'value': url}];
			products_request_ajax(params);
			break;
		case 'material-category':
			document.location.href = url;
			//material_request_ajax(url);
			break;
		case 'service-category':
			document.location.href = url;
			//material_request_ajax(url);
			break;
		case 'brand':
			var params = [{ 'key': 'url', 'value': url}];
			brand_request_ajax(params);
			break;
		case 'account_orders':
			return true;
	}
	return false;
});

/* изменение режима просмотра */
$('#content').on('click', '#view a', function(){
	var url = $(this).attr('href');
	var type = $(this).attr('data-type');
	if (url == undefined)
		return false;
		
	switch(type){
		case 'category':
			var params = [{ 'key': 'url', 'value': url}];
			products_request_ajax(params);
			break;
		case 'material-category':
			document.location.href = href;
			//material_request_ajax(url);
			break;
		case 'service-category':
			document.location.href = href;
			//material_request_ajax(url);
			break;
		case 'brand':
			var params = [{ 'key': 'url', 'value': url}];
			brand_request_ajax(params);
			break;
		case 'account_orders':
			return true;
	}
	return false;
});

/* Нажатие кнопки сбросить фильтр */
$('#wrap').on('click', '#clear-filter', function(){
	var params = [];
	params.push({
		'key': 'url',
		'value': $('div#filter').attr('data-url')
	});
	$('div#filter select').each(function(){
		$(this).val(0);
	});
	$('div#filter input:checked').each(function(){
		$(this).prop('checked', false);
	});
	$('div#filter input[data-control=rangeSlider]').each(function(){
		$(this).rangeSlider("updateSilent",{
			from: $(this).attr('data-min'),
			to: $(this).attr('data-max')
		});
	});
	products_request_ajax(params, undefined, undefined, true);
	return false;
});

/* Нажатие кнопки применить фильтр */
$('#wrap').on('click', '#apply_products_filter', function(event, scroll_up){
	var params = [];
	$('#filter_spinner').show();
	var scroll_to_up = (scroll_up == undefined || scroll_up == true) ? true : false;
	params.push({
		'key': 'url',
		'value': $('div#filter').attr('data-url')
	});
	$('div#filter select').each(function(){
		if ($(this).val().length != 0 && parseInt($(this).val()) != 0)
			params.push({
				'key': $(this).attr('name'),
				'value': $(this).val()
			});
	});
	$('div#filter input:checked:visible').each(function(){
		if ($(this).val().length != 0 && parseInt($(this).val()) != 0)
			params.push({
				'key': $(this).attr('name'),
				'value': $(this).val()
			});
	});
	$('div#filter input[data-control=rangeSlider]').each(function(){
		if ($(this).val().length != 0 && $(this).val() != ($(this).attr('data-min')+';'+$(this).attr('data-max')))
			params.push({
				'key': $(this).attr('name'),
				'value': $(this).val()
			});
			
	});
	products_request_ajax(params, undefined, undefined, true, scroll_to_up, false);
	return false;
});

function request_filter_products_count(){
	var params = [];
	params.push({
		'key': 'url',
		'value': $('div#filter').attr('data-url')
	},{
		'key': 'format',
		'value': 'products_count'
	});
	$('div#filter select').each(function(){
		if ($(this).val().length != 0 && parseInt($(this).val()) != 0)
			params.push({
				'key': $(this).attr('name'),
				'value': $(this).val()
			});
	});
	$('div#filter input:checked:visible').each(function(){
		if ($(this).val().length != 0 && parseInt($(this).val()) != 0)
			params.push({
				'key': $(this).attr('name'),
				'value': $(this).val()
			});
	});
	$('div#filter input[data-control=rangeSlider]').each(function(){
		if ($(this).val().length != 0 && $(this).val() != ($(this).attr('data-min')+';'+$(this).attr('data-max')))
			params.push({
				'key': $(this).attr('name'),
				'value': $(this).val()
			});
			
	});
	products_request_ajax(params, undefined, undefined, true, false, false);
	return false;
}

$('#wrap').on('change', 'div#filter input, div#filter select', function(){
	/*С запросом количества товаров, удовлетворяющих фильтру*/
	/*request_filter_products_count();*/
	/*Моментальное применение фильтра*/
	$('#apply_products_filter').trigger('click', false);
});

function rangeSliderStart(){
	$('input[data-control=rangeSlider]').rangeSlider({
		onChange: function(obj){        // function-callback, is called on every change
			if (!obj.input.data("disable"))
			{
				var i1 = $('#' + obj.input.attr('data-id') + "_min");
				var i2 = $('#' + obj.input.attr('data-id') + "_max");
			
				value_array = obj.input.val().split(";");
				i1.val(value_array[0]);
				i2.val(value_array[1]);
			}
		},
		onFinish: function(obj){
			/*var params = [];
			params.push({
				'key': 'url',
				'value': $('div#filter').attr('data-url')
			},{
				'key': 'format',
				'value': 'products_count'
			});
			$('div#filter select').each(function(){
				if ($(this).val().length != 0 && parseInt($(this).val()) != 0)
					params.push({
						'key': $(this).attr('name'),
						'value': $(this).val()
					});
			});
			$('div#filter input:checked:visible').each(function(){
				if ($(this).val().length != 0 && parseInt($(this).val()) != 0)
					params.push({
						'key': $(this).attr('name'),
						'value': $(this).val()
					});
			});
			$('div#filter input[data-control=rangeSlider]').each(function(){
				if ($(this).val().length != 0 && $(this).val() != $(this).attr('data-min')+';'+$(this).attr('data-max'))
					params.push({
						'key': $(this).attr('name'),
						'value': $(this).val()
					});
		
			});
			products_request_ajax(params, undefined, undefined, true);*/
			
			$('#apply_products_filter').trigger('click', false);
		}
	});
}
rangeSliderStart();

$('.container').on('click', 'div.selected-tags span.tag i.fa-times', function(){
	var mode = $(this).closest('span').data('mode');
	var name = $(this).closest('span').data('name');
	var tag = $(this).closest('span').data('tag');
	
	switch(mode){
		case "range":
			var object = $('#filter input[name='+name+']');
			object.rangeSlider("updateSilent",{
				from: object.data('min'),
				to: object.data('max')
			});
			break;
		case "checkbox":
			var object = $('#filter input[name='+name+'][value='+tag+']');
			object.prop('checked', false);
			break;
		case "radio":
			var object = $('#filter input[name='+name+'][value='+tag+']');
			object.prop('checked', false);
			object = $('#filter input[name='+name+'][value=0]');
			object.prop('checked', true);
			break;
		case "select":
			var object = $('#filter select[name='+name+']');
			object.val(0);
			break;
		case "logical":
			var object = $('#filter input[name='+name+']');
			object.prop('checked', false);
			break;
	}
	
	request_filter_products_count();
	$('#apply_products_filter').trigger('click', false);
	
	$(this).closest('span').remove();
	
	return false;
});

$('#main-container').on('keyup', 'input.rangeSlider' , function(){
	var slider = $('#'+$(this).attr('data-object'));
	value_array = slider.val().split(";");
	var i1_value = parseInt(value_array[0]);
	var i2_value = parseInt(value_array[1]);
	var min_value = parseInt(slider.attr('data-min'));
				var max_value = parseInt(slider.attr('data-max'));
	var from_value = parseInt($('#'+slider.attr('data-id')+'_min').val());
	var to_value = parseInt($('#'+slider.attr('data-id')+'_max').val());

	var new_value = parseInt($(this).val());

	if ($(this).attr('data-control') == "from")
	{
		if (new_value > i2_value)
			new_value = i2_value;
		if (new_value < min_value || !new_value)
			new_value = min_value;
		if (i2_value != to_value && (new_value <= to_value) && (to_value <= max_value))
			i2_value = to_value;
		slider.rangeSlider("updateSilent",{
			from: new_value,
			to: i2_value
		});
	}
	if ($(this).attr('data-control') == "to")
	{
		if (new_value < i1_value)
			new_value = i1_value;
		if (new_value > max_value || !new_value)
			new_value = max_value;
		if (i1_value != from_value && (min_value <= from_value) && (from_value <= new_value))
			i1_value = from_value;
		slider.rangeSlider("updateSilent",{
			from: i1_value,
			to: new_value
		});
	}
});

/* Изменение параметров фильтра
$('#content').on('change', 'form#form_tags select', function(){
	$('#apply_products_filter').trigger('click');
	return false;
});*/

/* Выбор бренда */
$('#brands').on('click', 'a', function(e){
	
	if (e.ctrlKey)	/*При зажатом Ctrl открываем ссылку в новом окне*/
	{
		var win = window.open($(this).attr('href'), '_blank');
		return false;
	}
	
	$("body,html").animate({
		scrollTop:0
	}, 0);
	
	var params = [];
	params.push({
		'key': 'url',
		'value': $(this).attr('href')
	});
	
	brand_request_ajax(params);
	return false;
});

/* Переход по ссылке на карточку товара */
$('#content').on('click', 'a.producthref', function(e){
	var url = $(this).attr('href');
	
	if (e.ctrlKey)	/*При зажатом Ctrl открываем ссылку в новом окне*/
	{
		var win = window.open(url, '_blank');
		return false;
	}
	
	$("body,html").animate({
		scrollTop:0
	}, 0);
	product_request_ajax(url);
	return false;
});

/* Переход по ссылке на материал */
$('#content').on('click', 'a.materialhref', function(e){
	var url = $(this).attr('href');
	
	if (e.ctrlKey)	/*При зажатом Ctrl открываем ссылку в новом окне*/
	{
		var win = window.open(url, '_blank');
		return false;
	}	
	
	$("body,html").animate({
		scrollTop:0
	}, 0);
	material_request_ajax(url);
	return false;
});

$('#make-compare').click(function(){
	compare_request_ajax();
	return false;
});

/* развернуть вертикальное меню */
$('div.vertical-menu').on('click', 'i.fa-plus', function(){

	var li = $(this).closest('li');
	var ol = $(this).closest('div.vertical-menu');

	/* Свернем другие категории */
	/*$('ol.vertical-menu > li:not(.collapsed)').each(function()/*/
	ol.find('> li:not(.collapsed)').each(function(){
		var li_find = $(this).find('li[data-id='+li.attr('data-id')+']');
		if (li_find.length == 0)
		{
			var icon_minus = $(this).find('i.fa-minus');
			if (icon_minus.length > 0)
				icon_minus.trigger('click');
		}
	});
	
	var ol = li.find('ol:first');
	var icon_col = li.find('i.fa-minus:first');
	var icon_exp = li.find('i.fa-plus:first');
	var icon_spin = li.find('i.fa-spinner:first');
	
	icon_exp.hide();
	
	ol.show();
	icon_col.show();
	
	li.removeClass('collapsed');
});

/* свернуть вертикальное меню */
$('div.vertical-menu').on('click', 'i.fa-minus', function(){
	var li = $(this).closest('li');
	var ol = li.find('ol:first');
	var icon_col = li.find('i.fa-minus:first');
	var icon_exp = li.find('i.fa-plus:first');
	
	ol.hide();
	li.addClass('collapsed');
	icon_col.hide();
	icon_exp.show();
});

/* хлебные крошки *//*
$('#content').on('click', 'ol.breadcrumb a', function(e){
	var url = $(this).attr('href');
	var type = $(this).attr('data-type');
	if (url == undefined)
		return false;
	
	if (e.ctrlKey)	//При зажатом Ctrl открываем ссылку в новом окне
	{
		var win = window.open(url, '_blank');
		return false;
	}
	
	switch(type){
		case 'category':
			var params = [{
					'key': 'url',
					'value': url
				}];
			products_request_ajax(params);
			break;
		case 'material-category':
			material_request_ajax(url);
			break;
		default:
			location.href = url;
			break;
	}
	
	return false;
});*/

$('ul.horizontal-menu a, #categories-menu a, #categories-menu-columns a').click(function(e){
	var type = $(this).attr('data-type');
	var href = $(this).attr('href');
	
	if (e.ctrlKey)	/*При зажатом Ctrl открываем ссылку в новом окне*/
	{
		var win = window.open(href, '_blank');
		return false;
	}
	
	switch(type){
		case 'category':
			var params = [{ 'key': 'url', 'value': href}];
			products_request_ajax(params);
			break;
		case 'product':
			product_request_ajax(href);
			break;
		case 'material':
			document.location.href = href;
			//material_request_ajax(href);
			break;
		case 'material-category':
			document.location.href = href;
			//material_request_ajax(href);
			break;
		case 'service':
			document.location.href = href;
			//material_request_ajax(href);
			break;
		case 'service-category':
			document.location.href = href;
			//material_request_ajax(href);
			break;
		case 'href':
			document.location.href = href;
			break;
		case 'sitemap':
			document.location.href = href;
			break;
		case 'reviews':
			document.location.href = href;
			break;
	}
	
	/*Код от полного шаблона default*/
	/*if (type != 'separator')
	{
		$(this).closest('ul').find('li.active').each(function(){
			$(this).removeClass('active');
		});
		
		$(this).closest('li').addClass('active');
		
		$('#main-left-container').show();
		if ($('#main-right-container').hasClass('col-xs-12'))
		{
			$('#main-right-container').removeClass('col-xs-12');
			$('#main-right-container').addClass('col-xs-9');
		}
	}*/
	if (type != 'separator')
	{
		$(this).closest('ul').find('li.active').each(function(){
			$(this).removeClass('active');
		});
		
		$(this).closest('li').addClass('active');
	}
	return false;
});

$('ul.horizontal-menu li.dropdown, #categories-menu li.dropdown, #categories-menu-columns li.dropdown').hover(function() {
	$(this).find('.dropdown-menu:first').stop(true, true).show();
}, function() {
	$(this).find('.dropdown-menu:first').stop(true, true).hide();
});

$('#cart-placeholder').hover(function() {
	$(this).find('.dropdown-menu:first').stop(true, true).show();
	$('#cart').addClass('hover');
}, function() {
	$(this).find('.dropdown-menu:first').stop(true, true).hide();
	$('#cart').removeClass('hover');
});

$('#cart-placeholder').on('click', 'a.incart', function(){
	var href = $(this).attr('href');
	var li = $(this).closest('li');
	var a_href = li.find('div.cart-product-name a.producthref');
	$.get(href, function(data){
		if (data.success)
		{
			if ($('#cart-placeholder li.in-cart-item').length == 1)
			{
				$('#cart').replaceWith(data.header);
				$('#cart-placeholder ul').remove();
				
				if ($('table.carttable').length > 0)
					$('table.carttable').closest('form').html('Корзина пуста');
			}
			else
			{
				li.remove();
				
				$('#cart').replaceWith(data.header);
				$('#cart-placeholder div.cart-intotal span').html(data.total_price_display + '<span class="currency"></span>');
				
				var tr = $('#content table.carttable div.carttable-product-name a[href="'+a_href.attr('href')+'"]');
				if (tr.length > 0)
				{
					tr.closest('tr').html('<td class="carttable-info" colspan="5">Товар '+a_href[0].outerHTML+' был удален из корзины.</td>');
					update_price();
				}	
			}
		}
	});
	return false;
});

$('#searchButton').click(function(){
	/*
	var curli = $('#refreshpart div.active');
	if (curli.length > 0)
		curli.click();
	else
	{
	*/
		add_to_search_history($('#searchField').val());
		var params = [{ 'key': 'keyword', 'value': encodeURIComponent($('#searchField').val())}, { 'key': 'format', 'value': 'search'}];
		products_request_ajax(params, true, true);
		$('#searchField').val('');
		$('#searchField').blur();
		$('#refreshpart').hide();
		$('#searchCancelButton').hide();
	/*
	}
	*/
	return false;
});

/* нажатие Enter в поле поиска */
$('#searchField').on('keydown', function(e){
	if (e.keyCode == 13)
	{
		$('#categories').hide();
		$('#categories-menu-columns li.active').removeClass('active');
		
		var curli = $('#refreshpart div.active');
		if (curli.length > 0)
			curli.click();
		else
			$('#searchButton').trigger('click');
		return false;
	}
});

/* поиск keyword */
$('#searchField').on('keyup', function(e){
	var keyword = "";
	
	if ($(this).val().length > 0)
		$('#searchCancelButton').show();
	else
		$('#searchCancelButton').hide();
	
	/* down or up */
	if (e.keyCode == 40 || e.keyCode == 38)
	{
		var curli = $('#refreshpart div.active');
		
		if (e.keyCode == 40)
		{
			var nextli = curli.next('div[data-id]');
			if (nextli.length == 0)
			{
				var next_block = curli.closest('.search-block').next('.search-block');
				if (next_block.length == 0)
					nextli = $('#refreshpart div[data-id]:first');
				else
					nextli = next_block.find('div[data-id]:first');
			}
				
		}
		if (e.keyCode == 38)
		{
			var nextli = curli.prev('div[data-id]');
			if (nextli.length == 0)
			{
				var prev_block = curli.closest('.search-block').prev('.search-block');
				if (prev_block.length == 0)
					nextli = $('#refreshpart div[data-id]:last');
				else
					nextli = prev_block.find('div[data-id]:last');
			}
		}	
			
		curli.removeClass('active');
		nextli.addClass('active');
	}
	else
	{
		if ($(this).val().length > 0)
		{
			/*$('#refreshpart').show();*/
			$('#searchCancelButton').show();
			keyword = $(this).val();
			
			if ($('#refreshpart').scrollTop() > 0)
				$('#refreshpart').scrollTop(0);
		}
		else
		{
			$('#refreshpart').hide();
		}
		search_request_ajax(keyword);
	}
});

$('#searchCancelButton').click(function(){
	$('#searchField').val('');
	search_request_ajax();
	$('#refreshpart').hide();
	$(this).hide();
	return false;
});

$('#refreshpart').on('mouseenter', '.search-block-results div[data-id]', function(){
	$('#refreshpart .search-block-results div.active').removeClass('active');
	$(this).addClass('active');
});

$('#refreshpart').on("click", ".search-block-results div[data-id]", function(){
	var li = $(this);
	var object_id = li.attr('data-id');
	var object_type = li.attr('data-object');
	var object_href = li.attr('data-href');
	var object_name = li.find('.app-productname span').html();
	$('#searchField').val('');
	$('#searchField').blur();
	$('#refreshpart').hide();
	$('#searchCancelButton').hide();
	add_to_search_history(object_name);
	if (object_type == "product")
		product_request_ajax(object_href);
	if (object_type == "category")
	{
		/*var params = [{ 'key': 'url', 'value': object_href}];
		products_request_ajax(params);*/
		document.location.href = object_href;
	}
	if (object_type == "brand")
	{
		var params = [{ 'key': 'url', 'value': object_href}];
		brand_request_ajax(params);
	}
	if (object_type == "href")
		document.location.href = object_href;
	return false;
});

$('#searchcategories').on('click', 'a.menu61', function(){
	var params = [{ 'key': 'url', 'value': $(this).attr('href')}];
	products_request_ajax(params);
	return false;
});

$('#help_msg1, #help_msg2').click(function(){
	$('#searchField').val($(this).html());
	$('#searchField').trigger('keyup');
	return false;
});

$('body').on('click', '#filter label[data-toggle=collapse]', function(){
	$(this).closest('div').find('i.fa-caret-right').first().toggle();
	$(this).closest('div').find('i.fa-caret-down').first().toggle();
});

$('body').on('click', '#filter i', function(){
	$(this).closest('div').find('label.labelcollapse').trigger('click');
});

$('html').click(function() {
	$('#refreshpart').hide();
});

$('#refreshpart').click(function(event){
	event.stopPropagation();
});

$('#pop-brands').click(function(){
    $(this).addClass('current');
    $('#all-brands').removeClass('current');
    $('#pop-brands-list').show();
    $('#all-brands-list').hide();
    return false;
});

$('#all-brands').click(function(){
    $(this).addClass('current');
    $('#pop-brands').removeClass('current');
    $('#pop-brands-list').hide();
    $('#all-brands-list').show();
    return false;
});

/* ФУНКЦИИ КОРЗИНЫ */
$('#content').on('click' , 'a.add_to_compare', function(){
	var a = $(this);
	var href = a.attr('href');
	$.get(href, function(data){
		if (data.success)
		{
			$('#make-compare').attr('href', data.compare_href);
			$('#make-compare').html(data.compare_href_label);
			if (data.compare_href_show)
				$('#make-compare').closest('div').show();
			else
				$('#make-compare').closest('div').hide();
			a.closest('div').hide().next('.buy-compare').show().find('a.compare_items').attr('href', data.compare_href).html('Сравнить ('+data.compare_items_count+')');
			a.closest('ul.list').find('a.compare_items').each(function(){
				$(this).attr('href', data.compare_href);
				$(this).html('Сравнить ('+data.compare_items_count+')');
			});
		}
	});
	return false;
});


$('#content').on('click', 'a.remove_from_compare', function(){
	var a = $(this);
	var href = a.attr('href');
	$.get(href, function(data){
		if (data.success)
		{
			$('#make-compare').attr('href', data.compare_href);
			$('#make-compare').html(data.compare_href_label);
			if (data.compare_href_show)
				$('#make-compare').closest('div').show();
			else
				$('#make-compare').closest('div').hide();
			a.closest('div').hide().prev('.buy-compare').show();
			a.closest('ul.list').find('a.compare_items').each(function(){
				$(this).attr('href', data.compare_href);
				$(this).html('Сравнить ('+data.compare_items_count+')');
			});
		}
	});
	return false;
});

$('#make-callback, #callback-mobile').click(function(){
	$('#contact-center input[name=phone_number]').val(user_phone).mask("+1 (999) 999-9999");
	$('#contact-center input[name=user_name]').val(user_name);
	$('#contact-center input[name=call_time]').val('');
	$('#contact-center textarea[name=message]').val('');
	
	$('#callback-part').show();
	$('#send-callback').show();
	$('#callback-success-part').hide();
	$('#callback-error-part').hide();
	$('#callback-close').hide();
	$('#contact-center-phone-required').hide();
	
	$('#contact-center').modal();
	if ($('#contact-center input[name=phone_number]').val() == "")
		$('#contact-center input[name=phone_number]').focus();
	else
		if ($('#contact-center input[name=user_name]').val() == "")
			$('#contact-center input[name=user_name]').focus();
		else
			$('#contact-center input[name=call_time]').focus();
	return false;
});

$('#content').on('click', 'a.review-complain', function(){
	$('#claim-part').show();
	$('#make-claim').show();
	$('#claim-success-part').hide();
	$('#claim-error-part').hide();
	$('#claim-close').hide();
	
	$('#complain-form .modal-body textarea').val('').prop('disabled', true);
	$('#complain-form .modal-body input[type=radio]:first').prop('checked', true);
	$('#complain-form input[name=claim_review_id]').val($(this).closest('.review-body').data('id'));
	$('#complain-form input[name=claim_review_type]').val('');
	$('#complain-form').modal();
	return false;
});

$('#content').on('click', '.block-class .group-products-filter .group-products-filter-element', function(){
	var type = $(this).data('type-id');
	$(this).closest('.group-products-filter').find('.group-products-filter-element').removeClass('active');
	$(this).addClass('active');
	
	var $owl = $(this).closest(".block-class").find(".owl-tiles");
	$all = $(this).closest(".block-class").find(".plitka-item").parent();
	if (!$owl.data("items-all")) $owl.data("items-all", $all);
	else $all = $owl.data("items-all");
	if (type) $filtered = $all.find(".plitka-item[data-type-id='"+type+"']").parent();
	else $filtered = $all;
	$filtered_clone = $filtered.clone(true);
	
	$all.each(function(i){
		$owl.trigger('del.owl.carousel', 0);
	});
	$($filtered_clone.get().reverse()).each(function(i){
		$owl.trigger('add.owl.carousel', [$(this), 0]);
	});
	$owl.trigger('update.owl.carousel');
	$owl.trigger('refresh.owl.carousel');
	return false;
});

$(".owl-tiles").on('initialized.owl.carousel changed.owl.carousel refreshed.owl.carousel', function (event) {
		if (!event.namespace) return;
		var carousel = event.relatedTarget,
				element = event.target,
				current = carousel.current();
		$('.owl-next', element).toggleClass('disabled', current === carousel.maximum());
		$('.owl-prev', element).toggleClass('disabled', current === carousel.minimum());
	});
	
	bind_widgets();
  $(document).ajaxStop(function() {
    bind_widgets();
  });
	
  // Disable scroll zooming and bind back the click event
  var onMapMouseleaveHandler = function (event) {
    var that = $(this);

    that.on('click', onMapClickHandler);
    that.off('mouseleave', onMapMouseleaveHandler);
    that.find('iframe').css("pointer-events", "none");
  }

  var onMapClickHandler = function (event) {
    var that = $(this);

    // Disable the click handler until the user leaves the map area
    that.off('click', onMapClickHandler);

    // Enable scrolling zoom
    that.find('iframe').css("pointer-events", "auto");

    // Handle the mouse leave event
    that.on('mouseleave', onMapMouseleaveHandler);
  }

  // Enable map zooming with mouse scroll when the user clicks the map
  $('.service-area-map').on('click', onMapClickHandler);
	
});

function bind_widgets()
{
	$('input, textarea').placeholder();
	owl_init();
}

function owl_init()
{
	$(".owl-tiles:not(.inited)").each(function(){
		$(this).addClass("inited").owlCarousel({
			smartSpeed: 300,
			loop: false,
			items: $(this).data("owl-items")?$(this).data("owl-items"):4,
			autoWidth: true,
			dots: false,
			nav: true,
			navText: ['<', '>']
		})
	});
}



$(window).load(function(){
	responsive_update();
	header_replace_update();
});

$(window).on("resize orientationchange", function(e){
	responsive_update();
	header_replace_update();
});

$(window).scroll(function(){
	if ($("html.lte-ie9").length) return false;
	if (typeof $("body").data("scrollval") === "undefined") $("body").data("scrollval", 0);
	header_scroll_update();
	$("body").data("scrollval", $(window).scrollTop());
});

$(document).ready(function () {
	$(window).scroll(function () {
			if ($(this).scrollTop() > 0)
					$('#scroller').fadeIn();
			else
					$('#scroller').fadeOut();
	});
	$('#scroller').click(function () {
			$('body,html').animate({ scrollTop: 0 }, 400);
			return false;
	});
});



function header_replace_update()
{
	$(".header-replace").height($(".header-bg").outerHeight());
}


function responsive_type()
{
	return $(".respon-meter").css("z-index");
}

function responsive_init()
{
	responsive_update();
}

function responsive_update()
{
	if (responsive_type() > 767) 
	{
		/*$("#topmenu").appendTo($(".top-menu .container"));*/
		//$("#make-callback").appendTo($("#topmenu"));
		/*$(".top-menu-line").insertBefore($(".header"));*/
		$(".header .header-right").appendTo($("header"));
		$(".footer-copyright").prependTo($("#footer .header-right"));
		
	}
	else
	{
		/*$(".top-menu-line").appendTo($(".slideout-menu"));
		$("#topmenu").appendTo($(".slideout-menu"));*/
		/*$("#topmenu").appendTo($(".header__overlay-inner"));
		$(".top-menu-line").appendTo($(".header__overlay-inner"));*/
		//$("#make-callback").appendTo($(".phone-call-wrapper"));
		$(".footer-copyright").appendTo($("#footer .header-right"));
	}
}

function header_scroll_update()
{
	if ($(".header").length)
	{
		var offset_top = $(".header").offset().top;
		if ($(".header").hasClass("header--fixed")) offset_top = $(".header-replace").offset().top;
		var height = $(".header").outerHeight() - $(".phone-call-wrapper").outerHeight();
		var used_height = height;
		if (responsive_type() > 767) used_height = 0;
		if ($(window).scrollTop() > $("body").data("scrollval") && $(window).scrollTop() > used_height + offset_top)
		{
			if (!$(".header").hasClass("header--compact") && !$("body").hasClass("header-overlay-active")) 
			{
				header_replace_update();
				$(".header").addClass("header--compact");
			}
		}
		else if ($(window).scrollTop() < used_height + offset_top/*$("body").data("scrollval")*/)
		{
			header_replace_update();
			if ($(".header").hasClass("header--compact")) $(".header").removeClass("header--compact");
		}
		
		if ($(window).scrollTop() > used_height + offset_top) 
		{
			if (!$(".header").hasClass("header--fixed"))
			{
				$(".header").addClass("notransition");
				$(".header")[0].offsetHeight;
				$(".header").addClass("header--scrolled header--fixed");
				$(".header")[0].offsetHeight;
				$(".header").removeClass("notransition");
			}
		}
		if ($(window).scrollTop() <=  offset_top) 
		{
			if ($(".header").hasClass("header--fixed")) $(".header").removeClass("header--scrolled header--fixed");
		}
	}
}



var addCSSRule = function (sheet_id, rules) {
  $("#"+sheet_id).remove();
  $("<style type='text/css' id='"+sheet_id+"'>"+rules+"</style>").appendTo("head");
};

var removeCSSRule = function (sheet_id) {
  $("#"+sheet_id).remove();
};

var delay = (function(){
  var timer = 0;
  return function(callback, ms){
    clearTimeout (timer);
    timer = setTimeout(callback, ms);
  };
})();



function touchclick()
{
  if ($(".no-touch").length) return "click";
  else return "touchstart";
}


$('a.show-cutted').click(function(){
	$(this).closest('div').find('div.cutted').removeClass('cutted');
	$(this).remove();
	return false;
});